import Dialog from "./Dialog";
import React, { useState} from "react";
import {VideoViewerDialog} from "./VideoViewerDialog";
import {
    Box,
    Grid,
    Typography
} from '@mui/material'

function VideoSection (props) {
    return (
        <Box bgcolor="black" onClick={props.onClick}>
            <img src={props.image} width="100%" alt="" style={{ borderRadius: 20, cursor: "pointer" }} />
            <Box mt={1}>
                <Typography style={{ color: "#f25721" }} variant="subtitle2">{props.header}</Typography>

                <Typography style={{ color: "white", fontWeight: "bold" }} variant="subtitle1">{props.title}</Typography>
                <Typography style={{ color: "#f25721" }} variant="caption">{props.runtime}</Typography>
                <Box py={0.0}/>
                <Typography style={{ color: '#f25721'  }} variant="subtitle1">{props.titleOrange}</Typography>
                <Typography style={{ color: "white" }} variant="caption">{props.title2}</Typography>
                <Box py={0.5}/>
                <Typography style={{ color: "white" }} variant="caption" >{props.name}</Typography>      <Box py={0.0}/>
                <Typography style={{ color: "white" }} variant="caption">{props.location}</Typography>
                <Box py={0.5}/>

                <Typography style={{ color: "white" }} variant="caption">{props.name02}</Typography>     <Box py={0.0}/>
                <Typography style={{ color: "white" }} variant="caption">{props.location02}</Typography>
                <Box py={0.5}/>

                <Typography style={{ color: "white" }} variant="caption">{props.name03}</Typography>     <Box py={0.0}/>
                <Typography style={{ color: "white" }} variant="caption">{props.location03}</Typography>
                <Box py={0.5}/>

                <Typography style={{ color: "white" }} variant="caption">{props.name04}</Typography>     <Box py={0.0}/>
                <Typography style={{ color: "white" }} variant="caption">{props.location04}</Typography>
                <Box py={0.5}/>

                <Typography style={{ color: "white" }} variant="caption">{props.name05}</Typography>     <Box py={0.0}/>
                <Typography style={{ color: "white" }} variant="caption">{props.location05}</Typography>
                <Box py={0.5}/>

                <Typography style={{ color: "white" }} variant="caption">{props.name06}</Typography>     <Box py={0.0}/>
                <Typography style={{ color: "white" }} variant="caption">{props.location06}</Typography>
            </Box>
        </Box>
    )
}

export function VideosDialog(props) {

    const { ...rest } = props;

    const [selectedVideo, setSelectedVideo] = useState();
    const [selectedVideoOpen    , setSelectedVideoOpen] = useState(false);

    const videos = [

        // {
        //     image: "/assets/videos/mastercard-gis/VOD/01_FIRESIDE_CHAT_MICHAEL_MIEBACH_RANDALL_TUCKER.jpg",
        //     // header: 'WELCOME MESSAGE',
        //     title: 'Fireside Chat',
        //     runtime: " (xx minutes)",
        //     name: "Michael Miebach + Randall Tucker",
        //     // location: "Hôpital Bicêtre, Université Paris Saclay,\n" +
        //     //     "France",
        //     url: "/assets/videos/mastercard-gis/VOD/01_FIRESIDE_CHAT_MICHAEL_MIEBACH_RANDALL_TUCKER.mp4",
        // },

        {
            image: "/assets/videos/mastercard-gis/VOD/01_FIRESIDE_CHAT_MICHAEL_MIEBACH_RANDALL_TUCKER.jpg",
            header: 'Day 1',
            title: 'Fireside Chat',
            runtime: " (22 minutes)",
            name: "Michael Miebach + Randall Tucker",
            url: "https://player.vimeo.com/video/757558128",
        },

        {
            image: "/assets/videos/mastercard-gis/VOD/02_EVOLVING_CULTURE_LUCRECIA_BORGONOVO.jpg",
            header: 'Day 1',
            title: 'Evolving culture',
            runtime: " (45 minutes)",
            name: "Lucrecia Borgonovo",
            url: "https://player.vimeo.com/video/757554629",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/03_EMPLOYEE_VOICES_COMPILATION .jpg",
            header: 'Day 1',
            title: 'Employee voices',
            runtime: " (1 minute)",
            name: "Compilation",
            url: "https://player.vimeo.com/video/757554534",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/04_Your_voice_Our_future_Randall_Tucker.jpg",
            header: 'Day 2',
            title: 'Your voice. Our future',
            runtime: " (40 minutes)",
            name: "Randall Tucker",
            url: "https://player.vimeo.com/video/757585685",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/05_Leadership_perspective_Tim_Murphy.jpg",
            header: 'Day 2',
            title: 'Leadership perspective',
            runtime: " (45 minutes)",
            name: "Tim Murphy",
            url: "https://player.vimeo.com/video/757588921",
        },



        {
            image: "/assets/videos/mastercard-gis/VOD/06_Wellness_presentation_Michael_Fracarro_Arianna_Huffington.jpg",
            header: 'Day 2',
            title: 'Wellness presentation',
            runtime: " (30 minutes)",
            name: "Michael Fracarro + Arianna Huffington",
            url: "https://player.vimeo.com/video/757590623",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/07_Year_of_Wellbeing.jpg",
            header: 'Day 2',
            title: 'Year of wellbeing',
            runtime: " (2 minutes 30 seconds)",
            name: "Brg Compilation",
            url: "https://player.vimeo.com/video/757553826",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/08_Closing_performance_Randall_Tucker_Michael_Rice.jpg",
            header: 'Day 2',
            title: 'Closing performance',
            runtime: " (22 minutes)",
            name: "Randall Tucker + Michael Rice",
            url: "https://player.vimeo.com/video/757687746",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/09_Regional_Presidents.jpg",
            header: 'Additional',
            title: 'Regional Presidents',
            runtime: " (4 minutes 47 seconds)",
            name: "Various",
            url: "https://player.vimeo.com/video/757552345",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/10_Gender_Balance.jpg",
            header: 'Additional',
            title: 'Gender balance',
            runtime: " (2 minutes 18 seconds)",
            name: "Ann Cairns",
            url: "https://player.vimeo.com/video/757547035",
        },


        {
            image: "/assets/videos/mastercard-gis/VOD/11_Inclusion_by_Design.jpg",
            header: 'Additional',
            title: 'Inclusion by design',
            runtime: " (3 minutes)",
            name: "Various",
            url: "https://player.vimeo.com/video/757546911",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/12_BRG_Compilation.jpg",
            header: 'Additional',
            title: 'BRG compilation video',
            runtime: " (2 minutes 38 seconds)",
            name: "Various",
            url: "https://player.vimeo.com/video/757546746",
        },
        {
            image: "/assets/videos/mastercard-gis/VOD/13_UpOnStrategy_Mike_Froman_FINAL_5_16.jpg",
            header: 'Additional',
            title: 'Up on strategy',
            runtime: " (4 minutes 45 seconds)",
            name: "Mike Froman",
            url: "https://player.vimeo.com/video/757544387",
        },

        // {
        //     image: "/assets/videos/mastercard-gis/VOD/14_MASTERCARD_WALKTHROUGH_EDIT_V02.jpg",
        //     header: 'Additional',
        //     title: 'Metaverse Walkthrough',
        //     runtime: " (2 minutes 44 seconds)",
        //     name: "Various",
        //     url: "/assets/videos/mastercard-gis/VOD/14_MASTERCARD_WALKTHROUGH_EDIT_V02.mp4",
        // },

        {
            image: "/assets/videos/mastercard-gis/VOD/14_MASTERCARD_WALKTHROUGH_EDIT_V02.jpg",
            header: 'Additional',
            title: 'Metaverse Walkthrough',
            runtime: " (2 minutes 44 seconds)",
            name: "Various",
            url: "https://player.vimeo.com/video/757575869",
        }


        // {
        //     image: "/assets/videos/mastercard-gis/VOD/null.jpg",
        //     header: '_____',
        //     title: 'Video',
        //     runtime: " (xx minutes)",
        //     name: "More_content_coming_soon",
        //     url: "/assets/videos/mastercard-gis/VOD/15null.mp4",
        // },
        //
        // {
        //     image: "/assets/videos/mastercard-gis/VOD/null.jpg",
        //     header: '_____',
        //     title: 'Video',
        //     runtime: " (xx minutes)",
        //     name: "More_content_coming_soon",
        //     url: "/assets/videos/mastercard-gis/VOD/16null.mp4",
        // },
        // {
        //     image: "/assets/videos/mastercard-gis/VOD/null.jpg",
        //     header: '_____',
        //     title: 'Video',
        //     runtime: " (xx minutes)",
        //     name: "More_content_coming_soon",
        //     url: "/assets/videos/mastercard-gis/VOD/17null.mp4",
        // },
        // {
        //     image: "/assets/videos/mastercard-gis/VOD/null.jpg",
        //     header: '_____',
        //     title: 'Video',
        //     runtime: " (xx minutes)",
        //     name: "More_content_coming_soon",
        //     url: "/assets/videos/mastercard-gis/VOD/18null.mp4",
        // },
        // {
        //     image: "/assets/videos/mastercard-gis/VOD/null.jpg",
        //     header: '_____',
        //     title: 'Video',
        //     runtime: " (xx minutes)",
        //     name: "More_content_coming_soon",
        //     url: "/assets/videos/mastercard-gis/VOD/19null.mp4",
        // },
        // {
        //     image: "/assets/videos/mastercard-gis/VOD/null.jpg",
        //     header: '_____',
        //     title: 'Video',
        //     runtime: " (xx minutes)",
        //     name: "More_content_coming_soon",
        //     url: "/assets/videos/mastercard-gis/VOD/20null.mp4",
        // },


    ]

    return (
        <>
            <Dialog bgcolor="black" analytics_id="oavideos" maxWidth="lg" {...rest}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'black'
                    }
                }}

            >
                {/*<Box bgcolor="white" py={2}></Box>*/}
                <Box width='100%' mb={2}>
                    <img src="/assets/images/ideaPharmaInnoverse/mcVodMastHead.jpg" width="100%" alt="" />
                </Box>
                <Box
                    p={4}
                    bgcolor="black"
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    style={{ display: "flex", overflowY: "scroll" }}
                >
                    {/* Videos  */}
                    <Grid container spacing={4} justify="space-between">
                        {videos.map((video, index) => (
                            <Grid key={index} item xs={12} md={4}>
                                <VideoSection
                                    image={video.image}
                                    header={video.header}
                                    title={video.title}
                                    runtime={video.runtime}
                                    titleOrange={video.titleOrange}
                                    title2={video.title2}
                                    name={video.name}
                                    location={video.location}
                                    name02={video.name02}
                                    location02={video.location02}
                                    name03={video.name03}
                                    location03={video.location03}
                                    name04={video.name04}
                                    location04={video.location04}
                                    name05={video.name05}
                                    location05={video.location05}
                                    name06={video.name06}
                                    location06={video.location06}

                                    onClick={() => {
                                        setSelectedVideo(video);
                                        setSelectedVideoOpen(true)
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Dialog>
            <VideoViewerDialog
                bgcolor="black"
                maxWidth="xl"
                fullWidth
                open={selectedVideoOpen}
                url={selectedVideo?.url}
                onClose={() => setSelectedVideoOpen(false)}
            />
        </>
    );
}