import React, { FunctionComponent } from 'react'
import UIAppBar from "../../components/UIAppBar";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ImageMap } from "@qiuz/react-image-map";

interface IdeaPharmaInnoverseHomePageProps {
    onInteracted: Function
}

const mapArea = [
    // {
    //     "id": "ZOOM",
    //     "width": "21.4%",
    //     "height": "39.5%",
    //     "left": "8.5%",
    //     "top": "12.0%",
    //     "href": "101"
    // },
    {
        "id": "METAVERSE",
        "width": "31.5%",
        "height": "8.0%",
        "left": "11.2%",
        "top": "79.0%",
        "href": "102"
    }
    // {
    //     "id": "CHAT",
    //     "width": "11.5%",
    //     "height": "15.0%",
    //     "left": "22.0%",
    //     "top": "80.5%",
    //     "href": "103"
    // },
]

const IdeaPharmaInnoverseHomePage : FunctionComponent<IdeaPharmaInnoverseHomePageProps> = (props) => {

    const onMapClick = async (area: any) => {
        if (area.id)
        {
            switch (area.id)
            {
                case 'METAVERSE': {
                    props.onInteracted();
                    break;
                }
            }
        }
    }

    return (

        <>
            <div className="click-region-outer">
                <div className="click-region-inner">
                    <ImageMap
                        className="click-region"
                        src="/assets/images/ideaPharmaInnoverse/ideaPharmaInnoverseSplash.jpg"
                        map={mapArea}
                        //commented out for holding
                        onMapClick={onMapClick}
                        //added for holding
                        // style={{
                        //     pointerEvents: "none"
                        // }}
                    />
                    <img
                        className="click-region"
                        src="/assets/images/ideaPharmaInnoverse/ideaPharmaInnoverseSplash.png"
                        width="100%"
                        alt=''
                        style={{
                            zIndex: 1000,
                            pointerEvents: "none"
                        }}
                    />
                </div>
            </div>
            <UIAppBar/>
        </>
    );
}

// todo - need to change
export default withAuthenticationRequired(IdeaPharmaInnoverseHomePage);



